<template>
  <div>
    <v-container fluid>
      <v-row align="center" class="list mx-auto">
        <v-col cols="6" md="2">
          <v-select
            v-model="pageSize"
            :items="pageSizes"
            label="Items/Página"
            centered
            @change="handlePageSizeChange"
          />
        </v-col>
        <v-col cols="12" md="8">
          <v-pagination
            v-model="page"
            :length="totalPages"
            total-visible="10"
            next-icon="mdi-menu-right"
            prev-icon="mdi-menu-left"
            @input="handlePageChange"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-card class="mx-auto list" width="95%" tile>
          <v-row>
            <v-card-title>Cotizaciones pendientes por autorizar</v-card-title>
            <v-spacer />
            <v-card-subtitle
              v-text="'Mostrando ítems ' + currentItems + ' de ' + totalItems"
            />
          </v-row>
          <v-data-table
            dense
            :headers="headers"
            :items="cotizaciones"
            disable-pagination
            :hide-default-footer="true"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-hover v-slot="{ hover }" open-delay="200">
                <v-icon
                  :elevation="hover ? 16 : 2"
                  :class="{ 'on-hover': hover }"
                  class="mr-2"
                  @click="(view_info = true), getcoti(item.id)"
                >
                  mdi-information-variant
                </v-icon>
              </v-hover>
            </template>
          </v-data-table>
          <v-card-actions v-if="oc_s.length > 0">
            <v-pagination
              v-model="page"
              :length="totalPages"
              total-visible="7"
              next-icon="mdi-menu-right"
              prev-icon="mdi-menu-left"
              @input="handlePageChange"
            />
          </v-card-actions>
        </v-card>
      </v-row>
    </v-container>
    <v-dialog v-if="view_info" v-model="view_info" scrollable>
      <v-card class="pa-6" color="white" outlined height="70%" width="60%">
        <v-card-title>Detalle cotizacion</v-card-title>
        <v-row class="list">
          <v-col md="2">
            <v-text-field
              v-model="cotdata.n_coti"
              prepend-inner-icon="mdi-file-document-outline"
              label="N° Cotizacion"
              readonly
            />
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field
              v-mask="['X.XXX.XXX-X', 'XX.XXX.XXX-X']"
              v-model="cotdata.empresa.rut"
              readonly
              prepend-inner-icon="mdi-card-account-details-outline"
              maxlength="12"
              label="Rut proveedor"
            />
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              v-model="cotdata.empresa.nombre"
              label="Nombre proveedor"
              prepend-inner-icon="mdi-account-hard-hat"
              readonly
            />
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field
              v-model="cotdata.ceco"
              label="CECO"
              prepend-inner-icon="mdi-cash"
              hint="CECOs separados por coma"
              @change="findcecos()"
            />
          </v-col>
          <v-col md="2">
            <v-select
              v-model="c_contable"
              :items="cuentas"
              prepend-inner-icon="mdi-filter-menu-outline"
              label="Sel. Area"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row class="list"> </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import eventBus from "../EventBus";
export default {
  name: "cotizaciones",
  components: {},
  data: () => ({
    totalPages: 0,
    page: 1,
    pageSize: 100,
    pageSizes: [100, 250, 500],
    currentItems: "",
    totalItems: 0,
    view_info: false,
    oc_s: [],
    cuentas: [
      { text: "Infraestructura", value: "I", c_cont: "6100302" },
      { text: "Mantenimiento", value: "M", c_cont: "6100184" },
      { text: "Suministros", value: "R", c_cont: "6100303" },
      { text: "Operaciones", value: "O", c_cont: "6100183" },
      { text: "Servicios Generales", value: "S", c_cont: "" },
      { text: "Infraestructura", value: "I", c_cont: "6100302" },
    ],
    c_contable: "",
    headers: [
      { text: "Fecha", value: "FECHA", align: "start", sortable: false },
      {
        text: "Solicitante",
        value: "SOLICITANTE",
        width: "120px",
        sortable: false,
      },
      { text: "Empresa", value: "EMPRESA", align: "start", sortable: false },
      {
        text: "Concepto",
        value: "CONCEPTO",
        align: "start",
        sortable: false,
      },
      { text: "$ Costo", value: "COSTO", align: "center", sortable: false },
      {
        text: "V°B° MTTO",
        value: "VB_MTTO",
        align: "left",
        width: "120px",
        sortable: false,
      },
      {
        text: "V°B° Gerencia",
        value: "VB_GERENCIA",
        align: "left",
        width: "120px",
        sortable: false,
      },
      { text: "Ver", value: "actions", align: "center", sortable: false },
    ],
    cotizaciones: [],
    rawdata: [],
    cotdata: [],
  }),
  methods: {
    getdata() {
      this.rawdata = [
        {
          n_coti: "cot-20220001",
          fecha: "2021-11-26",
          usr_sol: "solicitante1",
          empresa: {
            rut: "78594621-7",
            nombre: "Torneros asociados limitada",
          },
          concept: "rectificar eje",
          cost: 56251,
          cod_area: "",
          c_contable: "",
          ceco: "",
          status: 0,
          n_informe: "",
          n_roi: null,
          id_sap: "",
          f_ingr_sap: "",
          vb_mtto: true,
          usr_vb_mtto: "",
          f_vb_mtto: undefined,
          vb_gerencia: false,
          usr_vb_gerencia: "",
          f_vb_gerencia: undefined,
        },
        {
          n_coti: "cot-20220002",
          fecha: "2021-11-26",
          usr_sol: "solicitante1",
          empresa: {
            rut: "78594621-7",
            nombre: "Torneros asociados limitada",
          },
          concept: "rectificar eje",
          cost: 56251,
          cod_area: "",
          c_contable: "",
          ceco: "",
          n_informe: "",
          n_roi: null,
          id_sap: "",
          f_ingr_sap: "",
          vb_mtto: true,
          usr_vb_mtto: "",
          f_vb_mtto: undefined,
          vb_gerencia: false,
          usr_vb_gerencia: "",
          f_vb_gerencia: undefined,
        },
        {
          n_coti: "cot-20220003",
          fecha: "2021-11-26",
          usr_sol: "solicitante1",
          empresa: {
            rut: "78594621-7",
            nombre: "Torneros asociados limitada",
          },
          concept: "rectificar eje",
          cost: 56251,
          cod_area: "",
          c_contable: "",
          ceco: "",
          n_informe: "",
          n_roi: null,
          id_sap: "",
          f_ingr_sap: "",
          vb_mtto: true,
          usr_vb_mtto: "",
          f_vb_mtto: undefined,
          vb_gerencia: false,
          usr_vb_gerencia: "",
          f_vb_gerencia: undefined,
        },
      ];
      this.cotizaciones = this.rawdata.map(this.mapData);
    },
    getcoti(id) {
      let cot = this.rawdata.find((el) => el.n_coti === id);
      this.cotdata = cot;
      let rawdata2 = [
        {
          AÑO: 2021,
          MES: "01 ENERO",
          CUENTA: 6100183,
          AREA: "OPERACIONES",
          PPTO_CC: 24746821.3496041,
        },
        {
          AÑO: 2021,
          MES: "01 ENERO",
          CUENTA: 6100184,
          AREA: "EQUIPOS",
          PPTO_CC: 27091233.5684366,
        },
        {
          AÑO: 2021,
          MES: "01 ENERO",
          CUENTA: 6100302,
          AREA: "INFRAESTRUCTURA",
          PPTO_CC: 42109056.4620711,
        },
        {
          AÑO: 2021,
          MES: "01 ENERO",
          CUENTA: 6100303,
          AREA: "SUMINISTROS",
          PPTO_CC: 9764499.22798478,
        },
        {
          AÑO: 2021,
          MES: "01 ENERO",
          CUENTA: "A6100000",
          AREA: "MATERIALES",
          PPTO_CC: 74240464.0488122,
        },
        {
          AÑO: 2021,
          MES: "02 FEBRERO",
          CUENTA: 6100183,
          AREA: "OPERACIONES",
          PPTO_CC: 21013706.2766894,
        },
        {
          AÑO: 2021,
          MES: "02 FEBRERO",
          CUENTA: 6100184,
          AREA: "EQUIPOS",
          PPTO_CC: 16635850.8023791,
        },
        {
          AÑO: 2021,
          MES: "02 FEBRERO",
          CUENTA: 6100302,
          AREA: "INFRAESTRUCTURA",
          PPTO_CC: 36228848.4664135,
        },
        {
          AÑO: 2021,
          MES: "02 FEBRERO",
          CUENTA: 6100303,
          AREA: "SUMINISTROS",
          PPTO_CC: 8322354.30632748,
        },
        {
          AÑO: 2021,
          MES: "02 FEBRERO",
          CUENTA: "A6100000",
          AREA: "MATERIALES",
          PPTO_CC: 63041118.830068,
        },
        {
          AÑO: 2021,
          MES: "03 MARZO",
          CUENTA: 6100183,
          AREA: "OPERACIONES",
          PPTO_CC: 28778660.0876236,
        },
        {
          AÑO: 2021,
          MES: "03 MARZO",
          CUENTA: 6100184,
          AREA: "EQUIPOS",
          PPTO_CC: 22783105.902702,
        },
        {
          AÑO: 2021,
          MES: "03 MARZO",
          CUENTA: 6100302,
          AREA: "INFRAESTRUCTURA",
          PPTO_CC: 46476881.7615923,
        },
        {
          AÑO: 2021,
          MES: "03 MARZO",
          CUENTA: 6100303,
          AREA: "SUMINISTROS",
          PPTO_CC: 11192442.1143667,
        },
        {
          AÑO: 2021,
          MES: "03 MARZO",
          CUENTA: "A6100000",
          AREA: "MATERIALES",
          PPTO_CC: 86335980.2628709,
        },
        {
          AÑO: 2021,
          MES: "04 ABRIL",
          CUENTA: 6100183,
          AREA: "OPERACIONES",
          PPTO_CC: 26045435.6152428,
        },
        {
          AÑO: 2021,
          MES: "04 ABRIL",
          CUENTA: 6100184,
          AREA: "EQUIPOS",
          PPTO_CC: 20619303.1954006,
        },
        {
          AÑO: 2021,
          MES: "04 ABRIL",
          CUENTA: 6100302,
          AREA: "INFRAESTRUCTURA",
          PPTO_CC: 43515888.5831797,
        },
        {
          AÑO: 2021,
          MES: "04 ABRIL",
          CUENTA: 6100303,
          AREA: "SUMINISTROS",
          PPTO_CC: 10224425.1137318,
        },
        {
          AÑO: 2021,
          MES: "04 ABRIL",
          CUENTA: "A6100000",
          AREA: "MATERIALES",
          PPTO_CC: 78136306.8457285,
        },
        {
          AÑO: 2021,
          MES: "05 MAYO",
          CUENTA: 6100183,
          AREA: "OPERACIONES",
          PPTO_CC: 25964837.0301701,
        },
        {
          AÑO: 2021,
          MES: "05 MAYO",
          CUENTA: 6100184,
          AREA: "EQUIPOS",
          PPTO_CC: 20555495.982218,
        },
        {
          AÑO: 2021,
          MES: "05 MAYO",
          CUENTA: 6100302,
          AREA: "INFRAESTRUCTURA",
          PPTO_CC: 43428573.449351,
        },
        {
          AÑO: 2021,
          MES: "05 MAYO",
          CUENTA: 6100303,
          AREA: "SUMINISTROS",
          PPTO_CC: 10195879.7815186,
        },
        {
          AÑO: 2021,
          MES: "05 MAYO",
          CUENTA: "A6100000",
          AREA: "MATERIALES",
          PPTO_CC: 77894511.0905104,
        },
        {
          AÑO: 2021,
          MES: "06 JUNIO",
          CUENTA: 6100183,
          AREA: "OPERACIONES",
          PPTO_CC: 27033388.8599749,
        },
        {
          AÑO: 2021,
          MES: "06 JUNIO",
          CUENTA: 6100184,
          AREA: "EQUIPOS",
          PPTO_CC: 21401432.8474801,
        },
        {
          AÑO: 2021,
          MES: "06 JUNIO",
          CUENTA: 6100302,
          AREA: "INFRAESTRUCTURA",
          PPTO_CC: 44586171.2649728,
        },
        {
          AÑO: 2021,
          MES: "06 JUNIO",
          CUENTA: 6100303,
          AREA: "SUMINISTROS",
          PPTO_CC: 10574325.2212411,
        },
        {
          AÑO: 2021,
          MES: "06 JUNIO",
          CUENTA: "A6100000",
          AREA: "MATERIALES",
          PPTO_CC: 81100166.5799247,
        },
        {
          AÑO: 2021,
          MES: "07 JULIO",
          CUENTA: 6100183,
          AREA: "OPERACIONES",
          PPTO_CC: 27957651.5414592,
        },
        {
          AÑO: 2021,
          MES: "07 JULIO",
          CUENTA: 6100184,
          AREA: "EQUIPOS",
          PPTO_CC: 22133140.8036552,
        },
        {
          AÑO: 2021,
          MES: "07 JULIO",
          CUENTA: 6100302,
          AREA: "INFRAESTRUCTURA",
          PPTO_CC: 45587455.8365808,
        },
        {
          AÑO: 2021,
          MES: "07 JULIO",
          CUENTA: 6100303,
          AREA: "SUMINISTROS",
          PPTO_CC: 10901668.2542668,
        },
        {
          AÑO: 2021,
          MES: "07 JULIO",
          CUENTA: "A6100000",
          AREA: "MATERIALES",
          PPTO_CC: 83872954.6243775,
        },
        {
          AÑO: 2021,
          MES: "08 AGOSTO",
          CUENTA: 6100183,
          AREA: "OPERACIONES",
          PPTO_CC: 28189015.6062827,
        },
        {
          AÑO: 2021,
          MES: "08 AGOSTO",
          CUENTA: 6100184,
          AREA: "EQUIPOS",
          PPTO_CC: 22316304.0216405,
        },
        {
          AÑO: 2021,
          MES: "08 AGOSTO",
          CUENTA: 6100302,
          AREA: "INFRAESTRUCTURA",
          PPTO_CC: 45838100.2401396,
        },
        {
          AÑO: 2021,
          MES: "08 AGOSTO",
          CUENTA: 6100303,
          AREA: "SUMINISTROS",
          PPTO_CC: 10983609.6938918,
        },
        {
          AÑO: 2021,
          MES: "08 AGOSTO",
          CUENTA: "A6100000",
          AREA: "MATERIALES",
          PPTO_CC: 84567046.8188482,
        },
        {
          AÑO: 2021,
          MES: "09 SEPTIEMBRE",
          CUENTA: 6100183,
          AREA: "OPERACIONES",
          PPTO_CC: 26301711.7630809,
        },
        {
          AÑO: 2021,
          MES: "09 SEPTIEMBRE",
          CUENTA: 6100184,
          AREA: "EQUIPOS",
          PPTO_CC: 20822188.4791057,
        },
        {
          AÑO: 2021,
          MES: "09 SEPTIEMBRE",
          CUENTA: 6100302,
          AREA: "INFRAESTRUCTURA",
          PPTO_CC: 43793521.076671,
        },
        {
          AÑO: 2021,
          MES: "09 SEPTIEMBRE",
          CUENTA: 6100303,
          AREA: "SUMINISTROS",
          PPTO_CC: 10315189.5827578,
        },
        {
          AÑO: 2021,
          MES: "09 SEPTIEMBRE",
          CUENTA: "A6100000",
          AREA: "MATERIALES",
          PPTO_CC: 78905135.2892428,
        },
        {
          AÑO: 2021,
          MES: "10 OCTUBRE",
          CUENTA: 6100183,
          AREA: "OPERACIONES",
          PPTO_CC: 27196542.437071,
        },
        {
          AÑO: 2021,
          MES: "10 OCTUBRE",
          CUENTA: 6100184,
          AREA: "EQUIPOS",
          PPTO_CC: 21530596.0960145,
        },
        {
          AÑO: 2021,
          MES: "10 OCTUBRE",
          CUENTA: 6100302,
          AREA: "INFRAESTRUCTURA",
          PPTO_CC: 44762920.9734935,
        },
        {
          AÑO: 2021,
          MES: "10 OCTUBRE",
          CUENTA: 6100303,
          AREA: "SUMINISTROS",
          PPTO_CC: 10632108.779796,
        },
        {
          AÑO: 2021,
          MES: "10 OCTUBRE",
          CUENTA: "A6100000",
          AREA: "MATERIALES",
          PPTO_CC: 81589627.3112129,
        },
        {
          AÑO: 2021,
          MES: "11 NOVIEMBRE",
          CUENTA: 6100183,
          AREA: "OPERACIONES",
          PPTO_CC: 27474401.8310544,
        },
        {
          AÑO: 2021,
          MES: "11 NOVIEMBRE",
          CUENTA: 6100184,
          AREA: "EQUIPOS",
          PPTO_CC: 21750568.1162514,
        },
        {
          AÑO: 2021,
          MES: "11 NOVIEMBRE",
          CUENTA: 6100302,
          AREA: "INFRAESTRUCTURA",
          PPTO_CC: 45063935.3169755,
        },
        {
          AÑO: 2021,
          MES: "11 NOVIEMBRE",
          CUENTA: 6100303,
          AREA: "SUMINISTROS",
          PPTO_CC: 10730517.3151651,
        },
        {
          AÑO: 2021,
          MES: "11 NOVIEMBRE",
          CUENTA: "A6100000",
          AREA: "MATERIALES",
          PPTO_CC: 82423205.4931631,
        },
        {
          AÑO: 2021,
          MES: "12 DICIEMBRE",
          CUENTA: 6100183,
          AREA: "OPERACIONES",
          PPTO_CC: 27732753.2946832,
        },
        {
          AÑO: 2021,
          MES: "12 DICIEMBRE",
          CUENTA: 6100184,
          AREA: "EQUIPOS",
          PPTO_CC: 21955096.3582909,
        },
        {
          AÑO: 2021,
          MES: "12 DICIEMBRE",
          CUENTA: 6100302,
          AREA: "INFRAESTRUCTURA",
          PPTO_CC: 45343816.0692402,
        },
        {
          AÑO: 2021,
          MES: "12 DICIEMBRE",
          CUENTA: 6100303,
          AREA: "SUMINISTROS",
          PPTO_CC: 10822016.791867,
        },
        {
          AÑO: 2021,
          MES: "12 DICIEMBRE",
          CUENTA: "A6100000",
          AREA: "MATERIALES",
          PPTO_CC: 83198259.8840497,
        },
        {
          AÑO: 2022,
          MES: "01 ENERO",
          CUENTA: 6100183,
          AREA: "OPERACIONES",
          PPTO_CC: 13234994.1445755,
        },
        {
          AÑO: 2022,
          MES: "01 ENERO",
          CUENTA: 6100184,
          AREA: "EQUIPOS",
          PPTO_CC: 43087338.5064856,
        },
        {
          AÑO: 2022,
          MES: "01 ENERO",
          CUENTA: 6100302,
          AREA: "INFRAESTRUCTURA",
          PPTO_CC: 32952069.561654,
        },
        {
          AÑO: 2022,
          MES: "01 ENERO",
          CUENTA: 6100303,
          AREA: "SUMINISTROS",
          PPTO_CC: 13336557.1567693,
        },
        {
          AÑO: 2022,
          MES: "01 ENERO",
          CUENTA: "A6100000",
          AREA: "MATERIALES",
          PPTO_CC: 76941969.8228712,
        },
        {
          AÑO: 2022,
          MES: "02 FEBRERO",
          CUENTA: 6100183,
          AREA: "OPERACIONES",
          PPTO_CC: 13113834.5283279,
        },
        {
          AÑO: 2022,
          MES: "02 FEBRERO",
          CUENTA: 6100184,
          AREA: "EQUIPOS",
          PPTO_CC: 42692895.9142525,
        },
        {
          AÑO: 2022,
          MES: "02 FEBRERO",
          CUENTA: 6100302,
          AREA: "INFRAESTRUCTURA",
          PPTO_CC: 32517670.0843712,
        },
        {
          AÑO: 2022,
          MES: "02 FEBRERO",
          CUENTA: 6100303,
          AREA: "SUMINISTROS",
          PPTO_CC: 13214467.7829829,
        },
        {
          AÑO: 2022,
          MES: "02 FEBRERO",
          CUENTA: "A6100000",
          AREA: "MATERIALES",
          PPTO_CC: 76237605.3603531,
        },
        {
          AÑO: 2022,
          MES: "03 MARZO",
          CUENTA: 6100183,
          AREA: "OPERACIONES",
          PPTO_CC: 15234937.5296977,
        },
        {
          AÑO: 2022,
          MES: "03 MARZO",
          CUENTA: 6100184,
          AREA: "EQUIPOS",
          PPTO_CC: 49598277.362011,
        },
        {
          AÑO: 2022,
          MES: "03 MARZO",
          CUENTA: 6100302,
          AREA: "INFRAESTRUCTURA",
          PPTO_CC: 40122564.0547767,
        },
        {
          AÑO: 2022,
          MES: "03 MARZO",
          CUENTA: 6100303,
          AREA: "SUMINISTROS",
          PPTO_CC: 15351847.7549082,
        },
        {
          AÑO: 2022,
          MES: "03 MARZO",
          CUENTA: "A6100000",
          AREA: "MATERIALES",
          PPTO_CC: 88568690.7646855,
        },
        {
          AÑO: 2022,
          MES: "04 ABRIL",
          CUENTA: 6100183,
          AREA: "OPERACIONES",
          PPTO_CC: 13558015.6846319,
        },
        {
          AÑO: 2022,
          MES: "04 ABRIL",
          CUENTA: 6100184,
          AREA: "EQUIPOS",
          PPTO_CC: 44138955.0232185,
        },
        {
          AÑO: 2022,
          MES: "04 ABRIL",
          CUENTA: 6100302,
          AREA: "INFRAESTRUCTURA",
          PPTO_CC: 34110214.4328366,
        },
        {
          AÑO: 2022,
          MES: "04 ABRIL",
          CUENTA: 6100303,
          AREA: "SUMINISTROS",
          PPTO_CC: 13662057.5071867,
        },
        {
          AÑO: 2022,
          MES: "04 ABRIL",
          CUENTA: "A6100000",
          AREA: "MATERIALES",
          PPTO_CC: 78819863.6334512,
        },
        {
          AÑO: 2022,
          MES: "05 MAYO",
          CUENTA: 6100183,
          AREA: "OPERACIONES",
          PPTO_CC: 14107785.3251053,
        },
        {
          AÑO: 2022,
          MES: "05 MAYO",
          CUENTA: 6100184,
          AREA: "EQUIPOS",
          PPTO_CC: 45928763.9449984,
        },
        {
          AÑO: 2022,
          MES: "05 MAYO",
          CUENTA: 6100302,
          AREA: "INFRAESTRUCTURA",
          PPTO_CC: 36081330.3198294,
        },
        {
          AÑO: 2022,
          MES: "05 MAYO",
          CUENTA: 6100303,
          AREA: "SUMINISTROS",
          PPTO_CC: 14216045.9829757,
        },
        {
          AÑO: 2022,
          MES: "05 MAYO",
          CUENTA: "A6100000",
          AREA: "MATERIALES",
          PPTO_CC: 82015963.2028774,
        },
        {
          AÑO: 2022,
          MES: "06 JUNIO",
          CUENTA: 6100183,
          AREA: "OPERACIONES",
          PPTO_CC: 14624692.0910785,
        },
        {
          AÑO: 2022,
          MES: "06 JUNIO",
          CUENTA: 6100184,
          AREA: "EQUIPOS",
          PPTO_CC: 47611585.7550034,
        },
        {
          AÑO: 2022,
          MES: "06 JUNIO",
          CUENTA: 6100302,
          AREA: "INFRAESTRUCTURA",
          PPTO_CC: 37934621.341186,
        },
        {
          AÑO: 2022,
          MES: "06 JUNIO",
          CUENTA: 6100303,
          AREA: "SUMINISTROS",
          PPTO_CC: 14736919.4003582,
        },
        {
          AÑO: 2022,
          MES: "06 JUNIO",
          CUENTA: "A6100000",
          AREA: "MATERIALES",
          PPTO_CC: 85021013.6286117,
        },
        {
          AÑO: 2022,
          MES: "07 JULIO",
          CUENTA: 6100183,
          AREA: "OPERACIONES",
          PPTO_CC: 14711770.8254104,
        },
        {
          AÑO: 2022,
          MES: "07 JULIO",
          CUENTA: 6100184,
          AREA: "EQUIPOS",
          PPTO_CC: 47895075.93731,
        },
        {
          AÑO: 2022,
          MES: "07 JULIO",
          CUENTA: 6100302,
          AREA: "INFRAESTRUCTURA",
          PPTO_CC: 38246828.9714818,
        },
        {
          AÑO: 2022,
          MES: "07 JULIO",
          CUENTA: 6100303,
          AREA: "SUMINISTROS",
          PPTO_CC: 14824666.3615483,
        },
        {
          AÑO: 2022,
          MES: "07 JULIO",
          CUENTA: "A6100000",
          AREA: "MATERIALES",
          PPTO_CC: 85527248.0308326,
        },
        {
          AÑO: 2022,
          MES: "08 AGOSTO",
          CUENTA: 6100183,
          AREA: "OPERACIONES",
          PPTO_CC: 15308254.9766657,
        },
        {
          AÑO: 2022,
          MES: "08 AGOSTO",
          CUENTA: 6100184,
          AREA: "EQUIPOS",
          PPTO_CC: 49836966.8258243,
        },
        {
          AÑO: 2022,
          MES: "08 AGOSTO",
          CUENTA: 6100302,
          AREA: "INFRAESTRUCTURA",
          PPTO_CC: 40385432.6707809,
        },
        {
          AÑO: 2022,
          MES: "08 AGOSTO",
          CUENTA: 6100303,
          AREA: "SUMINISTROS",
          PPTO_CC: 15425727.8270409,
        },
        {
          AÑO: 2022,
          MES: "08 AGOSTO",
          CUENTA: "A6100000",
          AREA: "MATERIALES",
          PPTO_CC: 88994923.5782764,
        },
        {
          AÑO: 2022,
          MES: "09 SEPTIEMBRE",
          CUENTA: 6100183,
          AREA: "OPERACIONES",
          PPTO_CC: 14709707.7136101,
        },
        {
          AÑO: 2022,
          MES: "09 SEPTIEMBRE",
          CUENTA: 6100184,
          AREA: "EQUIPOS",
          PPTO_CC: 47888359.3497887,
        },
        {
          AÑO: 2022,
          MES: "09 SEPTIEMBRE",
          CUENTA: 6100302,
          AREA: "INFRAESTRUCTURA",
          PPTO_CC: 38239431.996191,
        },
        {
          AÑO: 2022,
          MES: "09 SEPTIEMBRE",
          CUENTA: 6100303,
          AREA: "SUMINISTROS",
          PPTO_CC: 14822587.4177917,
        },
        {
          AÑO: 2022,
          MES: "09 SEPTIEMBRE",
          CUENTA: "A6100000",
          AREA: "MATERIALES",
          PPTO_CC: 85515254.0787275,
        },
        {
          AÑO: 2022,
          MES: "10 OCTUBRE",
          CUENTA: 6100183,
          AREA: "OPERACIONES",
          PPTO_CC: 14194444.5107108,
        },
        {
          AÑO: 2022,
          MES: "10 OCTUBRE",
          CUENTA: 6100184,
          AREA: "EQUIPOS",
          PPTO_CC: 46210888.2605888,
        },
        {
          AÑO: 2022,
          MES: "10 OCTUBRE",
          CUENTA: 6100302,
          AREA: "INFRAESTRUCTURA",
          PPTO_CC: 36392033.7216283,
        },
        {
          AÑO: 2022,
          MES: "10 OCTUBRE",
          CUENTA: 6100303,
          AREA: "SUMINISTROS",
          PPTO_CC: 14303370.1758965,
        },
        {
          AÑO: 2022,
          MES: "10 OCTUBRE",
          CUENTA: "A6100000",
          AREA: "MATERIALES",
          PPTO_CC: 82519758.5480734,
        },
        {
          AÑO: 2022,
          MES: "11 NOVIEMBRE",
          CUENTA: 6100183,
          AREA: "OPERACIONES",
          PPTO_CC: 14800283.0141962,
        },
        {
          AÑO: 2022,
          MES: "11 NOVIEMBRE",
          CUENTA: 6100184,
          AREA: "EQUIPOS",
          PPTO_CC: 48183232.8188698,
        },
        {
          AÑO: 2022,
          MES: "11 NOVIEMBRE",
          CUENTA: 6100302,
          AREA: "INFRAESTRUCTURA",
          PPTO_CC: 38564176.0358957,
        },
        {
          AÑO: 2022,
          MES: "11 NOVIEMBRE",
          CUENTA: 6100303,
          AREA: "SUMINISTROS",
          PPTO_CC: 14913857.7772692,
        },
        {
          AÑO: 2022,
          MES: "11 NOVIEMBRE",
          CUENTA: "A6100000",
          AREA: "MATERIALES",
          PPTO_CC: 86041815.8564105,
        },
        {
          AÑO: 2022,
          MES: "12 DICIEMBRE",
          CUENTA: 6100183,
          AREA: "OPERACIONES",
          PPTO_CC: 14755608.7219454,
        },
        {
          AÑO: 2022,
          MES: "12 DICIEMBRE",
          CUENTA: 6100184,
          AREA: "EQUIPOS",
          PPTO_CC: 48037792.9092091,
        },
        {
          AÑO: 2022,
          MES: "12 DICIEMBRE",
          CUENTA: 6100302,
          AREA: "INFRAESTRUCTURA",
          PPTO_CC: 38404003.1185265,
        },
        {
          AÑO: 2022,
          MES: "12 DICIEMBRE",
          CUENTA: 6100303,
          AREA: "SUMINISTROS",
          PPTO_CC: 14868840.6623742,
        },
        {
          AÑO: 2022,
          MES: "12 DICIEMBRE",
          CUENTA: "A6100000",
          AREA: "MATERIALES",
          PPTO_CC: 85782100.7399043,
        },
        {
          AÑO: 2022,
          MES: "01 ENERO",
          CUENTA: "SUB-M",
          AREA: "EQUIPOS",
          PPTO_CC: 19000000,
        },
        {
          AÑO: 2022,
          MES: "02 FEBRERO",
          CUENTA: "SUB-M",
          AREA: "EQUIPOS",
          PPTO_CC: 19000000,
        },
        {
          AÑO: 2022,
          MES: "03 MARZO",
          CUENTA: "SUB-M",
          AREA: "EQUIPOS",
          PPTO_CC: 19000000,
        },
        {
          AÑO: 2022,
          MES: "04 ABRIL",
          CUENTA: "SUB-M",
          AREA: "EQUIPOS",
          PPTO_CC: 19000000,
        },
        {
          AÑO: 2022,
          MES: "05 MAYO",
          CUENTA: "SUB-M",
          AREA: "EQUIPOS",
          PPTO_CC: 19000000,
        },
        {
          AÑO: 2022,
          MES: "06 JUNIO",
          CUENTA: "SUB-M",
          AREA: "EQUIPOS",
          PPTO_CC: 19000000,
        },
        {
          AÑO: 2022,
          MES: "07 JULIO",
          CUENTA: "SUB-M",
          AREA: "EQUIPOS",
          PPTO_CC: 19000000,
        },
        {
          AÑO: 2022,
          MES: "08 AGOSTO",
          CUENTA: "SUB-M",
          AREA: "EQUIPOS",
          PPTO_CC: 19000000,
        },
        {
          AÑO: 2022,
          MES: "09 SEPTIEMBRE",
          CUENTA: "SUB-M",
          AREA: "EQUIPOS",
          PPTO_CC: 19000000,
        },
        {
          AÑO: 2022,
          MES: "10 OCTUBRE",
          CUENTA: "SUB-M",
          AREA: "EQUIPOS",
          PPTO_CC: 19000000,
        },
        {
          AÑO: 2022,
          MES: "11 NOVIEMBRE",
          CUENTA: "SUB-M",
          AREA: "EQUIPOS",
          PPTO_CC: 19000000,
        },
        {
          AÑO: 2022,
          MES: "12 DICIEMBRE",
          CUENTA: "SUB-M",
          AREA: "EQUIPOS",
          PPTO_CC: 19000000,
        },
        {
          AÑO: 2022,
          MES: "01 ENERO",
          CUENTA: "SUB-I",
          AREA: "INFRAESTRUCTURA",
          PPTO_CC: 14500000,
        },
        {
          AÑO: 2022,
          MES: "02 FEBRERO",
          CUENTA: "SUB-I",
          AREA: "INFRAESTRUCTURA",
          PPTO_CC: 14500000,
        },
        {
          AÑO: 2022,
          MES: "03 MARZO",
          CUENTA: "SUB-I",
          AREA: "INFRAESTRUCTURA",
          PPTO_CC: 14500000,
        },
        {
          AÑO: 2022,
          MES: "04 ABRIL",
          CUENTA: "SUB-I",
          AREA: "INFRAESTRUCTURA",
          PPTO_CC: 14500000,
        },
        {
          AÑO: 2022,
          MES: "05 MAYO",
          CUENTA: "SUB-I",
          AREA: "INFRAESTRUCTURA",
          PPTO_CC: 14500000,
        },
        {
          AÑO: 2022,
          MES: "06 JUNIO",
          CUENTA: "SUB-I",
          AREA: "INFRAESTRUCTURA",
          PPTO_CC: 14500000,
        },
        {
          AÑO: 2022,
          MES: "07 JULIO",
          CUENTA: "SUB-I",
          AREA: "INFRAESTRUCTURA",
          PPTO_CC: 14500000,
        },
        {
          AÑO: 2022,
          MES: "08 AGOSTO",
          CUENTA: "SUB-I",
          AREA: "INFRAESTRUCTURA",
          PPTO_CC: 14500000,
        },
        {
          AÑO: 2022,
          MES: "09 SEPTIEMBRE",
          CUENTA: "SUB-I",
          AREA: "INFRAESTRUCTURA",
          PPTO_CC: 14500000,
        },
        {
          AÑO: 2022,
          MES: "10 OCTUBRE",
          CUENTA: "SUB-I",
          AREA: "INFRAESTRUCTURA",
          PPTO_CC: 14500000,
        },
        {
          AÑO: 2022,
          MES: "11 NOVIEMBRE",
          CUENTA: "SUB-I",
          AREA: "INFRAESTRUCTURA",
          PPTO_CC: 14500000,
        },
        {
          AÑO: 2022,
          MES: "12 DICIEMBRE",
          CUENTA: "SUB-I",
          AREA: "INFRAESTRUCTURA",
          PPTO_CC: 14500000,
        },
        {
          AÑO: 2022,
          MES: "01 ENERO",
          CUENTA: "OTROS",
          AREA: "INFRAESTRUCTURA",
          PPTO_CC: 5815485.76404833,
        },
        {
          AÑO: 2022,
          MES: "02 FEBRERO",
          CUENTA: "OTROS",
          AREA: "INFRAESTRUCTURA",
          PPTO_CC: 20089072.5982571,
        },
        {
          AÑO: 2022,
          MES: "03 MARZO",
          CUENTA: "OTROS",
          AREA: "INFRAESTRUCTURA",
          PPTO_CC: 8998299.09798777,
        },
        {
          AÑO: 2022,
          MES: "04 ABRIL",
          CUENTA: "OTROS",
          AREA: "INFRAESTRUCTURA",
          PPTO_CC: 5873030.07493186,
        },
        {
          AÑO: 2022,
          MES: "05 MAYO",
          CUENTA: "OTROS",
          AREA: "INFRAESTRUCTURA",
          PPTO_CC: 6113091.25223924,
        },
        {
          AÑO: 2022,
          MES: "06 JUNIO",
          CUENTA: "OTROS",
          AREA: "INFRAESTRUCTURA",
          PPTO_CC: 7377616.80324428,
        },
        {
          AÑO: 2022,
          MES: "07 JULIO",
          CUENTA: "OTROS",
          AREA: "INFRAESTRUCTURA",
          PPTO_CC: 5619039.02495778,
        },
        {
          AÑO: 2022,
          MES: "08 AGOSTO",
          CUENTA: "OTROS",
          AREA: "INFRAESTRUCTURA",
          PPTO_CC: 6469828.97778098,
        },
        {
          AÑO: 2022,
          MES: "09 SEPTIEMBRE",
          CUENTA: "OTROS",
          AREA: "INFRAESTRUCTURA",
          PPTO_CC: 7019205.54237429,
        },
        {
          AÑO: 2022,
          MES: "10 OCTUBRE",
          CUENTA: "OTROS",
          AREA: "INFRAESTRUCTURA",
          PPTO_CC: 6440040.41567452,
        },
        {
          AÑO: 2022,
          MES: "11 NOVIEMBRE",
          CUENTA: "OTROS",
          AREA: "INFRAESTRUCTURA",
          PPTO_CC: 6349220.0278621,
        },
        {
          AÑO: 2022,
          MES: "12 DICIEMBRE",
          CUENTA: "OTROS",
          AREA: "INFRAESTRUCTURA",
          PPTO_CC: 6982256.92875264,
        },
      ];
      console.log(rawdata2[0]);
    },
    handlePageChange(value) {
      this.page = value;
    },
    handlePageSizeChange(size) {
      this.pageSize = size;
      this.page = 1;
    },
    mapData(data) {
      return {
        id: data.n_coti,
        FECHA: data.fecha,
        SOLICITANTE: data.usr_sol,
        EMPRESA: data.empresa["nombre"],
        CONCEPTO: data.concept,
        COSTO: Intl.NumberFormat("es-CL", {
          style: "currency",
          currency: "CLP",
        }).format(data.cost),
        VB_MTTO: data.vb_mtto,
        VB_GERENCIA: data.vb_gerencia,
      };
    },
  },
  mounted() {
    this.getdata();
    eventBus.$emit("title-select", "Cotizaciones de Servicio");
  },
};
</script>
